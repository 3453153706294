import React, { useState } from 'react';
import { Button } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { GridDraw } from './GridDraw';
import './login.css';

const SAMPLES = ['2', '3', 'Ч', 'S', '6', '8', '9', 'E', 'T', 'U', 'P', 'F', 'H', 'L', 'C', 'Ц', 'Г', 'Ш', 'Щ', 'П', 'Э', 'Ь', ']', '[', ':]', ':[', '⛶', '▢', '◧', '=', '÷', '+'];

const getRandomSample = () => SAMPLES.sort(() => Math.random() - 0.5)[0];

const PictureAuthStep = ({ onComplete }) => {
  // const handleFileAttach = ({ target: { files } }) => {
  //   if (!files.length) return;
  //
  //   const reader = new FileReader();
  //   reader.addEventListener('load', ({ target: { result: fileBody } }) => {
  //     onComplete(fileBody)
  //   });
  //   reader.readAsDataURL(files[0]);
  // };

  const [sample, setSample] = useState(getRandomSample());

  return (
    <div className="login">
      <p
        style={{ height: '80px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={() => setSample(getRandomSample())}
      >
        {/*Выберите картинку для защиты кода*/}
        Нарисуйте графический ключ для входа
      </p>

      <p
        style={{
          backgroundColor: 'var(--tg-theme-bg-color)',
          color: 'var(--tg-theme-hint-color)',
          caretColor: 'transparent',
          lineHeight: '20px',
          padding: '15px 5px',
          pointerEvents: 'none',
          textAlign: 'center'
        }}
      >
        {/*Картинка никуда не отправляется и нужна только для генерации ключа*/}
        Картинка нужна для генерации ключа.<br/>
      Например, это может быть символ:&nbsp; <b><span style={{ fontSize: '20px', display: 'inline-block', transform: `rotate(${[0, 90, 270].sort(() => Math.random() - 0.5)[0]}deg)` }}>{sample}</span></b><br/>
        Запомните место и всегда рисуйте там.
      </p>

      <div className="numbers-panel-wrapper">
        <GridDraw onComplete={onComplete} />
      </div>

      {/*<div className="numbers-panel-wrapper">
        <Button
          variant="text"
          component="label"
          sx={{
            backgroundColor: 'var(--tg-theme-bg-color) !important',
            color: 'var(--tg-theme-text-color)',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 40px)',
            textAlign: 'center',
            width: 'calc(100% - 40px)',
          }}
        >
          <div style={{ height: '20px' }}/>
          <ImageSearchIcon fontSize="large" />
          <div style={{
              color: 'var(--tg-theme-hint-color)',
              fontSize: '12px',
              fontWeight: '400',
              padding: '20px 15px',
            }}>
            Нажмите для выбора картинки
          </div>
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileAttach}
          />
        </Button>
      </div>*/}
    </div>
  );
};

export default PictureAuthStep;
