export const getFingerprint = async () => {
  const fingerprintData = (await window.App.getFingerprint()).components;
  const fingerprint = {
    canvas: fingerprintData.canvas?.value,
    osCpu: fingerprintData.osCpu?.value,
    colorDepth: fingerprintData.colorDepth?.value,
    deviceMemory: fingerprintData.deviceMemory?.value,
    screenResolution: fingerprintData.screenResolution?.value,
    hardwareConcurrency: fingerprintData.hardwareConcurrency?.value,
    cpuClass: fingerprintData.cpuClass?.value,
    platform: fingerprintData.platform?.value,
    touchSupport: fingerprintData.touchSupport?.value,
    colorGamut: fingerprintData.colorGamut?.value,
    math: fingerprintData.math?.value,
    architecture: fingerprintData.architecture?.value,
    webGlBasics: fingerprintData.webGlBasics?.value,
    webGlExtensions: fingerprintData.webGlExtensions?.value,
  };

  return fingerprint;
};
