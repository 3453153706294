import React, { useState, useCallback } from 'react';
import { Button } from '@mui/material';

export const GridDraw = ({ onComplete }) => {
  const size = 10;
  const minLength = 5;
  const [grid, setGrid] = useState(Array(size).fill().map(() => Array(size).fill(0)));
  const [isInverted, setIsInverted] = useState(false);
  const [isInteracting, setIsInteracting] = useState(false);
  const currentLength = grid.flat().filter(Boolean).length
  const isNonLile = () => {
    const verticalItems = [];
    const horizontalItems = [];
    grid.forEach((row, i) => {
      row.forEach((item, j) => {
        if (item) {
          if (!verticalItems.includes(i)) verticalItems.push(i);
          if (!horizontalItems.includes(j)) horizontalItems.push(j);
        }
      });
    });

    return verticalItems.length > 1 && horizontalItems.length > 1;
  };
  const isValid = isNonLile() && currentLength >= minLength && currentLength <= size * size - minLength;

  const getErrorText = () => {
    if (currentLength < minLength) return `Закрасьте хотя бы ${minLength} клеток`;
    if (currentLength > size * size - minLength) return `Оставьте хотя бы ${minLength} клеток`;
    if (!isNonLile()) return 'Прямые линии это слишком просто :)'
  };

  const handleContinue = () => {
    onComplete(grid.flat().join(''));
  }

  const toggleCell = useCallback((rowIndex, colIndex, inverted) => {
    setGrid(prevGrid => {
      const maxItem = Math.max(...prevGrid.flat());
      const newGrid = prevGrid.map(row => [...row]);
      const oldValue = newGrid[rowIndex][colIndex];
      newGrid[rowIndex][colIndex] = inverted ? 0 : oldValue || maxItem + 1;
      return newGrid;
    });
  }, []);

  const handleStart = useCallback((rowIndex, colIndex) => {
    setIsInteracting(true);
    const inverted = !!grid[rowIndex][colIndex];
    setIsInverted(inverted);
    toggleCell(rowIndex, colIndex, inverted);
  }, [grid, toggleCell]);

  const handleMove = useCallback((rowIndex, colIndex) => {
    if (isInteracting) {
      toggleCell(rowIndex, colIndex, isInverted);
    }
  }, [isInteracting, toggleCell, isInverted]);

  const handleEnd = (event) => {
    event.preventDefault();
    setIsInteracting(false);
  };

  const getCellPosition = (event, element) => {
    const rect = element.getBoundingClientRect();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const x = clientX - rect.left;
    const y = clientY - rect.top;

    const colIndex = Math.floor((x / rect.width) * size);
    const rowIndex = Math.floor((y / rect.height) * size);

    return { rowIndex, colIndex };
  };

  const handleTouchMove = useCallback((event) => {
    event.preventDefault();
    const element = event.currentTarget;
    const { rowIndex, colIndex } = getCellPosition(event, element);

    if (rowIndex >= 0 && rowIndex < size && colIndex >= 0 && colIndex < size) {
      handleMove(rowIndex, colIndex);
    }
  }, [handleMove]);

  const handleTouchStart = useCallback((event) => {
    event.preventDefault();
    const element = event.currentTarget;
    const { rowIndex, colIndex } = getCellPosition(event, element);

    if (rowIndex >= 0 && rowIndex < size && colIndex >= 0 && colIndex < size) {
      handleStart(rowIndex, colIndex);
    }
  }, [handleStart]);

  return (
    <div style={{
      width: 'calc(100% - 40px)',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
        style={{
          aspectRatio: '1 / 1',
          cursor: 'crosshair',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxHeight: 'calc(100vh - 300px)',
        }}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        onTouchEnd={handleEnd}
        onTouchCancel={handleEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex', flexGrow: 1, }}>
            {row.map((cell, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleStart(rowIndex, colIndex);
                }}
                onMouseEnter={(e) => {
                  e.preventDefault();
                  handleMove(rowIndex, colIndex);
                }}
                style={{
                  alignItems: 'center',
                  backgroundColor: cell ? 'var(--tg-theme-hint-color)' : 'var(--tg-theme-bg-color)',
                  border: '1px solid var(--tg-theme-secondary-bg-color)',
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
              >
                <div style={{
                  alignItems: 'center',
                  color: cell ? 'var(--tg-theme-text-color)' : 'rgba(255,255,255,0.15)',
                  display: 'flex',
                  fontSize: '12px',
                  justifyContent: 'center',
                  height: 0,
                  width: 0,
                }}>
                  {cell || `${'ABCDEFGHIJ'[rowIndex]}${colIndex + 1}`}
                </div>
              </div>
            ))}
          </div>
        ))}
        {[2, 4, 6, 8].map((x) =>
          [2, 4, 6, 8].map((y) => (
            <div
              key={`dot-${x}-${y}`}
              style={{
                background: 'rgba(255,255,255,0.15)',
                width: '3vmin',
                height: '3vmin',
                borderRadius: '3vmin',
                position: 'absolute',
                top: `${y * 100 / size}%`,
                left: `${x * 100 / size}%`,
                transform: 'translate(-50%, -50%)',
              }}
            />
          ))
        )}
      </div>
      <Button
        disabled={!isValid}
        variant="text"
        component="label"
        onClick={handleContinue}
        sx={{
          backgroundColor: 'var(--tg-theme-bg-color) !important',
          color: 'var(--tg-theme-text-color)',
          display: 'flex',
          flexDirection: 'column',
          height: '60px',
          marginTop: '20px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <div style={{
            color: 'var(--tg-theme-hint-color)',
            fontSize: '12px',
            fontWeight: '400',
            padding: '20px 15px',
          }}>
          {isValid ? 'Продолжить' : getErrorText()}
        </div>
      </Button>
    </div>
  );
};

export default GridDraw;
