import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sha512 } from 'utils/crypto';
import { wipeOutTraces } from 'utils/storage';
import { getUuid } from 'utils/uuid';
import { getFingerprint } from 'utils/fingerprint';

const initialState = {
  encryptionKey: process.env.REACT_APP_AUTH_PICTURE || null,
  picture: process.env.REACT_APP_AUTH_PICTURE || null,
  status: null,
  token: null,
  user: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'auth/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );
export const signin = createAsyncThunk(
  'auth/signin',
  async ([authAPI, credentials]) => {
    // -------------------------------------------------------------------------
    // REMEMBER: We have a goal that nobody can brute force account credentials.
    //           Fingerprint is easy to see if you forcefuly grab user`s device.
    //           Picture of 10x10 with 5 cells has ~ 10k variations 100*4*3*3*3.
    //           Password of 6 numbers has 1M variations but most could be easy.
    // -------------------------------------------------------------------------
    // Here are the rules we must follow:
    // 1) No one on internet should know userId + accountId used for logging in.
    // 2) Produced keys must be created on-the-fly or use something from server.
    // 3) Original password must be transformed before being sent to the server.
    // -------------------------------------------------------------------------

    const picture = credentials.picture;
    const rawPassword = credentials.password;
    const fingerprint = await getFingerprint();
    if (!picture || !rawPassword || !fingerprint) {
      throw new Error('No credentials provided!');
    }

    const accountId = getUuid({ picture, fingerprint });
    const accountSalt = await authAPI.generateSalt({ accountId });

    const userId = getUuid({ accountId, accountSalt, fingerprint, picture, rawPassword });
    const userSalt = await authAPI.generateSalt({ accountId, userId });
    const password = await sha512({ accountId, accountSalt, fingerprint, picture, rawPassword, userId, userSalt });

    const user = await authAPI.create({
      id: userId,
      password: password.slice(0, 64),
    });

    const encryptionKey = await sha512({ userSalt, password, picture, fingerprint });

    return { encryptionKey, user };
  }
);

export const hardSignout = createAsyncThunk(
  'auth/hardSignout',
  async (authAPI) => {
    window.navigator?.vibrate?.(100);
    wipeOutTraces();
    authAPI.destroy();
  }
);

export const signout = createAsyncThunk(
  'auth/signout',
  async (authAPI) => {
    window.navigator?.vibrate?.(100);
    wipeOutTraces();
    authAPI.destroy();
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // Use the PayloadAction type to declare the contents of `action.payload`
    // setCode: (state, action) => {
    //   state.code = action.payload;
    // },
    setPicture: (state, action) => {
      state.picture = action.payload;
    },
    updateAuth: (state, action) => {
      const [AuthAPI, params] = action.payload;
      AuthAPI.update(params);
      state.user.firebase_token = params.firebase_token;
      state.user.notify = params.notify;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(hardSignout.pending, (state) => {
        state.encryptionKey = null;
        state.picture = null;
        state.status = null;
        state.token = null;
        state.user = null;
      })
      .addCase(signout.pending, (state) => {
        state.encryptionKey = null;
        state.status = null;
        state.token = null;
        state.user = null;
      })
      .addCase(signin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.encryptionKey = action.payload.encryptionKey;
        state.token = action.payload.user.jwt;
        state.user = action.payload.user;
        state.status = 'ready';
      });
  },
});

export const {
  setPicture,
  updateAuth,
} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
// export const selectCount = (state) => state.auth.value;

export const selectEncryptionKey = (state) => state.auth.encryptionKey;
export const selectPicture = (state) => state.auth.picture;
export const selectStatus = (state) => state.auth.status;
export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default authSlice.reducer;
